<template>
    <div> 2</div>
</template>
<script>
  export default {
    data() {
      return {
        
      }
    },
    
  }
</script>